// FlixPreview.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Observer from '@researchgate/react-intersection-observer';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import SwagLoading from '../component/SwagLoading.jsx';
import StatefulImage from '../component/StatefulImage.jsx';
import LazyImage, { Image } from '../component/LazyImage.jsx';
import { getIsOnMobile } from '../resource/getUserAgent.js';
import { formatTimeString } from '../resource/formatTimeString.js';
import { ButtonId } from '../resource/mixpanel.js';
import NativeVideoPlayer from '../container/NativeVideoPlayer.js';
import DecryptionWrapper from '../container/DecryptionWrapper.js';
import FlixBadge from '../container/FlixBadge.js';
import { drmRulesetTestStatus as drmRulesetTestStatusConstant } from '../resource/drmConstants.js';
import { ProfileListType } from '../resource/profileConstants.js';
import { archiveType } from '../resource/archiveConstants.js';
import { MediaAssetFormat, MediaPreset } from '../resource/getMediaAsset.js';
import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import media from '../style/media.js';
import { color } from '../style/variables.js';

export class FlixPreview extends React.PureComponent {
  state = {
    shouldAutoPlay: false,
    isHovered: false,
    isVideoOnloaded: false,
    isIntersecting: false,
  };
  nextTicks = [];

  componentDidMount() {
    const { userId, username, fetchUser } = this.props;
    if (userId && !username) {
      this.nextTicks.push(setTimeout(() => fetchUser(userId)));
    }
    this.fetchAssetManifest();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      messageId,
      assetIds,
      duration,
      shouldPlay,
      isAuthed,
      userId,
      username,
      fetchUser,
    } = this.props;
    const { isIntersecting } = this.state;
    if (shouldPlay !== prevProps.shouldPlay) {
      if (shouldPlay) {
        this.autoPlayTimeout = setTimeout(
          () => this.setState({ shouldAutoPlay: true }),
          300 // TODO: remote config
        );
      } else {
        this.autoPlayTimeout && clearTimeout(this.autoPlayTimeout);
        this.setState({ shouldAutoPlay: false });
      }
    }
    if (isAuthed !== prevProps.isAuthed || userId !== prevProps.userId) {
      if (userId && !username) {
        this.nextTicks.push(setTimeout(() => fetchUser(userId)));
      }
    }
    if (
      messageId !== prevProps.messageId ||
      assetIds !== prevProps.assetIds ||
      duration !== prevProps.duration ||
      isIntersecting !== prevState.isIntersecting
    ) {
      this.fetchAssetManifest();
    }
  }

  componentWillUnmount() {
    if (this.mouseTimeout) clearTimeout(this.mouseTimeout);
    if (this.autoPlayTimeout) clearTimeout(this.autoPlayTimeout);
    this.nextTicks.forEach(clearTimeout);
  }

  fetchAssetManifest = () => {
    const { messageId, contentType, duration, assetIds, fetchAssetManifest } =
      this.props;
    const { isIntersecting } = this.state;
    const assetId = assetIds[0];
    const isVideo = contentType.startsWith('video/');
    if (duration || !isIntersecting || !messageId || !assetId || !isVideo) {
      return;
    }
    return fetchAssetManifest({ messageId, assetId });
  };

  handleOnMouseEnter = () => {
    this.mouseTimeout = setTimeout(
      () => this.setState({ isHovered: true }),
      300 // TODO: remote config
    );
  };

  handleOnMouseLeave = () => {
    if (this.mouseTimeout) clearTimeout(this.mouseTimeout);
    this.setState({ isHovered: false });
  };

  handleVideoOnloaded = () => {
    this.setState({ isVideoOnloaded: true });
  };
  getTrackUserClickData = () => {
    const {
      messageId,
      username,
      categoryIndex,
      categoryId,
      shouldTrackProfilePostEvent,
      shouldTrackCardEvent,
      shouldTrackRecommendEvent,
    } = this.props;
    const trackingPayload = {
      messageId,
      'user.username': username,
    };
    if (shouldTrackRecommendEvent) {
      return {
        dataElementId: ButtonId.Detail.ButtonRecommendFlix,
        dataTrackingPayload: {
          ...trackingPayload,
          'recommend.message.id': messageId,
        },
      };
    }
    if (shouldTrackCardEvent) {
      return {
        dataElementId: ButtonId.All.ButtonFlixCard,
        dataTrackingPayload: {
          ...trackingPayload,
          'discover.index': categoryIndex,
          'discover.category': categoryId,
        },
      };
    }
    if (shouldTrackProfilePostEvent) {
      return {
        dataElementId: ButtonId.Profile.ButtonPostClick,
        dataTrackingPayload: trackingPayload,
      };
    }

    return {
      dataElementId: null,
      dataTrackingPayload: null,
    };
  };
  renderUsernameTooltip = () => {
    const { username } = this.props;

    return <TooltipWrapper>{username}</TooltipWrapper>;
  };

  fillUpContainer = ({ videoWidth, videoHeight }) => {
    const targetRatio = 16 / 9;
    const currentRatio = videoWidth / videoHeight;

    if (currentRatio >= targetRatio) {
      this.setState({
        playerStyle: {
          flex: 'none',
          width: 'auto',
          height: '100%',
        },
      });
    } else if (currentRatio < targetRatio) {
      this.setState({
        playerStyle: {
          // flex: 'none',
          width: '100%',
          height: 'auto',
        },
      });
    }
  };

  handleIntersection = ({ isIntersecting }, unobserve) => {
    this.setState({ isIntersecting });
    if (isIntersecting) {
      unobserve();
    }
  };

  handleLoadedMetadata = (event, player) => {
    const videoWidth = player.videoWidth;
    const videoHeight = player.videoHeight;

    this.fillUpContainer({ videoWidth, videoHeight });
  };

  handleLinkClick = e => {
    const { shouldUseLink, messageId } = this.props;
    // For pages that needs to push to history stack, use with shouldUseLink, otherwise replace the history.
    // For example, from / to /flix/{messageId}, use Link (shouldUseLink: true)
    // From /flix/{messageId1} to /flix/{messageId2}, use replace (shouldUseLink: false)
    if (shouldUseLink) return;
    e.preventDefault();
    this.props.replace(`/post/${messageId}`);
  };

  renderVideo = () => {
    const { messageId, originalTitle, hasVerified, trailerSource } = this.props;
    const { playerStyle } = this.state;

    return (
      <VideoWrapper>
        <VideoContainer playerStyle={playerStyle}>
          <DecryptionWrapper resourceUrl={trailerSource}>
            <NativeVideoPlayer
              isMuted
              isLoop
              decryptionType="video/mp4"
              shouldUseTrailer={true} // this is for DecryptionWrapper
              trailerSource={trailerSource}
              messageId={messageId}
              alt={originalTitle}
              preset={hasVerified ? MediaPreset.SD : MediaPreset.SD_PREVIEW}
              onLoadedData={this.handleVideoOnloaded}
              onLoadedMetadata={this.handleLoadedMetadata}
            />
          </DecryptionWrapper>
        </VideoContainer>
      </VideoWrapper>
    );
  };
  getShouldRenderVideo = () => {
    const { drmRulesetTeststatus, shouldPlay } = this.props;
    const { isHovered } = this.state;
    const shouldRenderVideo =
      drmRulesetTeststatus !== drmRulesetTestStatusConstant.TESTING &&
      (shouldPlay || (isHovered && !getIsOnMobile()));
    return shouldRenderVideo;
  };
  getLocationState = () => {
    const {
      contentType,
      userId,
      shortVideoOrigin,
      duration,
      shortVideoDuration,
    } = this.props;
    const isVideo = contentType.startsWith('video/');
    const isShortVideo =
      // TODO: refactor this to re-use logic
      shortVideoDuration && isVideo && duration < shortVideoDuration;
    if (!isShortVideo) {
      return {};
    }
    if ('profile' === shortVideoOrigin) {
      return {
        listPath: ['outbox', ProfileListType.SHORT, userId],
      };
    }
    if ('archive' === shortVideoOrigin) {
      return {
        listPath: ['unlocked', 'messages', archiveType.SHORT, userId],
      };
    }
    return {};
  };

  renderThumbnail() {
    const { isVideoOnloaded } = this.state;
    const {
      messageId,
      thumbnailSource,
      blurredThumbnailSource,
      username,
      originalTitle,
      shouldAnimateLoader,
      shouldShowLoader,
      shouldUseLazyImage,
      hasVerified,
    } = this.props;
    const shouldRenderVideo = this.getShouldRenderVideo();
    if (!messageId) {
      return (
        <ImageWrapper shouldShowVideo={shouldRenderVideo && isVideoOnloaded}>
          {shouldShowLoader && (
            <SwagLoadingWrapper>
              <SwagLoading shouldAnimate={shouldAnimateLoader} />
            </SwagLoadingWrapper>
          )}
        </ImageWrapper>
      );
    }
    const jpgSrcSet = getPublicSrcSet({
      href: hasVerified ? thumbnailSource : blurredThumbnailSource,
      size: 256,
      format: MediaAssetFormat.JPG,
    });
    const sources = [MediaAssetFormat.AVIF, MediaAssetFormat.WEBP].map(
      format => {
        const srcSet = getPublicSrcSet({
          href: hasVerified ? thumbnailSource : blurredThumbnailSource,
          size: 256,
          format,
        });
        return (
          <source
            key={format}
            srcSet={srcSet.srcSet}
            type={`image/${format}`}
          />
        );
      }
    );
    const altText = `${username} : ${originalTitle}`;
    return (
      <ImageWrapper shouldShowVideo={shouldRenderVideo && isVideoOnloaded}>
        <StatefulImage>
          <StatefulImageWrapper data-key="loading">
            {shouldShowLoader && (
              <SwagLoadingWrapper>
                <SwagLoading shouldAnimate={shouldAnimateLoader} />
              </SwagLoadingWrapper>
            )}
          </StatefulImageWrapper>
          {shouldUseLazyImage ? (
            <LazyImage data-key="target" threshold={0}>
              <DecryptionWrapper resourceUrl={jpgSrcSet.src}>
                {sources}
                <Image {...jpgSrcSet} alt={altText} />
              </DecryptionWrapper>
            </LazyImage>
          ) : (
            <Picture data-key="target">
              <DecryptionWrapper resourceUrl={jpgSrcSet.src}>
                {sources}
                <img {...jpgSrcSet} alt={altText} />
              </DecryptionWrapper>
            </Picture>
          )}
        </StatefulImage>
      </ImageWrapper>
    );
  }

  renderBadge = () => {
    const { badges, messageBadgeNotShowList } = this.props;
    // TODO: fix badges sometimes get `[null]` during page switching
    return (
      <BadgeWrapper>
        {badges
          .filter(value => !messageBadgeNotShowList.includes(value))
          .map(value => (
            <FlixBadge key={value || Math.random()} badgeName={value} />
          ))}
      </BadgeWrapper>
    );
  };

  render() {
    const { messageId, duration, style, isOnFreezone, hasFreePeek } =
      this.props;

    const trackingData = this.getTrackUserClickData();
    const LinkProps = {
      to:
        isOnFreezone && hasFreePeek
          ? `/freezone/${messageId}`
          : `/post/${messageId}`,

      onClick: this.handleLinkClick,
      'data-element_id': trackingData.dataElementId,
      'data-tracking_payload': trackingData.dataTrackingPayload,
      as: Link,
      state: this.getLocationState(),
      onMouseOver: this.handleOnMouseEnter,
      onMouseOut: this.handleOnMouseLeave,
    };

    return (
      <Observer onChange={this.handleIntersection}>
        <StyledFlixPreview {...(messageId ? LinkProps : {})} style={style}>
          {this.renderBadge()}
          {this.renderThumbnail()}
          {this.getShouldRenderVideo() && this.renderVideo()}
          {!!duration && (
            <Duration>{formatTimeString(duration * 1000)}</Duration>
          )}
        </StyledFlixPreview>
      </Observer>
    );
  }
}

FlixPreview.propTypes = {
  messageId: PropTypes.string,
  categoryId: PropTypes.string,
  shortVideoOrigin: PropTypes.string,
  thumbnailSource: PropTypes.string,
  blurredThumbnailSource: PropTypes.string,
  trailerSource: PropTypes.string,
  drmRulesetTeststatus: PropTypes.string,
  contentType: PropTypes.string,
  originalTitle: PropTypes.string,
  categoryIndex: PropTypes.number,
  duration: PropTypes.number,
  shortVideoDuration: PropTypes.number,
  isAuthed: PropTypes.bool,
  shouldShowLoader: PropTypes.bool,
  shouldAnimateLoader: PropTypes.bool,
  shouldUseLink: PropTypes.bool,
  shouldUseLazyImage: PropTypes.bool,
  shouldTrackCardEvent: PropTypes.bool,
  shouldTrackRecommendEvent: PropTypes.bool,
  shouldTrackProfilePostEvent: PropTypes.bool,
  shouldPlay: PropTypes.bool,
  hasFreePeek: PropTypes.bool,
  isOnFreezone: PropTypes.bool,
  hasVerified: PropTypes.bool,
  replace: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchAssetManifest: PropTypes.func,
  userId: PropTypes.string,
  username: PropTypes.string,
  messageBadgeNotShowList: PropTypes.array,
  badges: PropTypes.array,
  assetIds: PropTypes.array,
  style: PropTypes.object,
};

FlixPreview.defaultProps = {
  categoryId: null,
  shortVideoOrigin: '',
  thumbnailSource: '',
  blurredThumbnailSource: '',
  trailerSource: '',
  drmRulesetTeststatus: '',
  contentType: '',
  categoryIndex: null,
  duration: null,
  shortVideoDuration: null,
  shouldPlay: false,
  hasFreePeek: false,
  isOnFreezone: false,
  messageId: '',
  isAuthed: false,
  shouldUseLink: false,
  shouldTrackCardEvent: false,
  shouldTrackRecommendEvent: false,
  shouldUseLazyImage: false,
  shouldTrackProfilePostEvent: false,
  hasVerified: false,
  messageBadgeNotShowList: [],
  badges: [],
  assetIds: [],
  replace: () => null,
  fetchUser: () => null,
  fetchAssetManifest: () => null,
  userId: '',
  username: '',
  originalTitle: '',
};

const VideoWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const VideoContainer = styled.div`
  ${({ playerStyle }) => playerStyle}
`;

const StyledFlixPreview = styled.div`
  display: block;
  position: relative;
  padding-bottom: 56%;
  width: 100%;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 1;
  border-radius: 8px 8px 0 0;

  &:hover {
    opacity: 1;
  }

  ${media.mobile`
    padding-bottom: 64%;
  `};
`;

const BadgeWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  z-index: 1;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${({ shouldShowVideo }) =>
    shouldShowVideo ? 'opacity 2s' : 'none'};
  opacity: ${({ shouldShowVideo }) => (shouldShowVideo ? 0 : 1)};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${color.neutral[10]};
`;

const StatefulImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & > img {
    width: 80%;
  }
`;

const Duration = styled.span`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 4px;
  border-radius: 4px;

  position: absolute;
  z-index: 3;
  bottom: 8px;
  right: 8px;
`;

const TooltipWrapper = styled.div`
  margin-top: 2px;
  padding: 4px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: rgb(246, 246, 246, 0.8);
  color: #191919;
  font-size: 12px;
  line-height: 1;
`;

const SwagLoadingWrapper = styled.div`
  width: 120px;
`;

const Picture = styled.picture`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default FlixPreview;
